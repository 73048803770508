<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <div class="content">
                <!-- <p class="title">留言建议</p> -->
                <!-- <div class="line max_big_box"></div> -->
                <div class="third max_small_box">
                     <div class="list">
                        <p class="name">您的行业<span>*</span></p>
                        <!-- <input v-model="form.industry" placeholder="请输入所属行业" /> -->
                        <!-- industryList, typeList -->
                        <select v-model="form.industry">
                            <option v-for="item in industryList" :value="item" :key="item">{{ item }}</option>
                        </select>
                    </div>
                    <div class="list">
                        <!-- <p class="name">类型</p> -->
                        <!-- <input v-model="form.type" placeholder="请输入类型" /> -->
                        <p class="name">服务类型<span>*</span></p>
                        <select v-model="form.type">
                            <option v-for="item in typeList" :value="item" :key="item">{{ item }}</option>
                        </select>
                    </div>
                    <!-- <div class="list">
                        <p class="name">联系人</p>
                        <input v-model="form.username" placeholder="请输入联系人" />
                    </div>
                    <div class="list">
                        <p class="name">联系电话</p>
                        <input v-model="form.phone" placeholder="请输入联系电话" />
                    </div>
                    <div class="list">
                        <p class="name">邮箱</p>
                        <input v-model="form.email" placeholder="请输入邮箱" />
                    </div> -->
                </div>
                <!-- <p class="title">留言内容</p> -->
                <div class="second max_small_box">
                    <p class="name">咨询内容<span>*</span></p>
                    <textarea v-model="form.content" placeholder="请输入留言内容"></textarea>
                </div>
                <div class="second max_small_box">
                    <p class="name">企业名称<span>*</span></p>
                    <input v-model="form.company" placeholder="请输入企业名称" />
                </div>
                <div class="third max_small_box">
                     <div class="list">
                        <!-- <p class="name">所属行业</p> -->
                        <p class="name">联系人<span>*</span></p>
                        <input v-model="form.username" placeholder="请输入联系人" />
                    </div>
                    <div class="list">
                        <!-- <p class="name">类型</p> -->
                        <p class="name">联系电话<span>*</span></p>
                        <input v-model="form.phone" placeholder="请输入联系电话" />
                    </div>
                </div>
                <div class="third max_small_box">
                     <div class="list">
                        <!-- <p class="name">所属行业</p> -->
                        <p class="name">所属地区"<span>*</span></p>
                        <input v-model="form.region" placeholder="请输入所属地区" />
                    </div>
                    <div class="list">
                        <!-- <p class="name">类型</p> -->
                        <p class="name">邮箱<span>*</span></p>
                        <input v-model="form.email" placeholder="请输入邮箱" />
                    </div>
                </div>
                <!-- <div class="third max_small_box">
                    <div class="list">
                        <p class="name">所属地区</p>
                        <input v-model="form.region" placeholder="请输入所属地区" />
                    </div>
                </div> -->
                <!-- <div class="first max_small_box">
                    <div class="list">
                        <p class="name">公司名称</p>
                        <input v-model="form.company" placeholder="请输入公司名称" />
                    </div>
                    <div class="list">
                        <p class="name">公司地址</p>
                        <input v-model="form.address" placeholder="请输入公司地址" />
                    </div>
                    <div class="list">
                        <p class="name">传真</p>
                        <input v-model="form.fax" placeholder="请输入传真" />
                    </div>
                </div> -->
                <button class="btn" @click="submit">咨询留言</button>
                <a-alert
                    v-show="alert"
                    style="width: 30%;margin:0 auto;margin-top:30px;"
                    :message="message"
                    banner
                />
                <a-alert v-show="success" message="成功" type="success" style="width: 30%;margin:0 auto;margin-top:30px;" />
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ContactTop from '../../components/contact/ContactTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//表单处理逻辑
const formEffect = () => {

    const industryList = reactive(['请选择您的行业', '食品', '服装', '家具', '轻工', '玩具和婴童', '化工建材', '线缆', '照明', '电子信息', '智能加工装备', '环保', '大健康'])
    const typeList = reactive(['请选择服务类型', '检测服务', '标准化服务', '评价服务', '认证服务', '计量服务', '定制化服务', '政务相关服务', '科研创新合作', '培训服务', '资源共享', '质量比对', '失效分析', '质量控制和管理服务', '其他服务', '投诉和建议'])

    const form = reactive({
        username: '',
        phone: '',
        email: '',
        content: '',
        region: '',
        industry: '请选择您的行业',
        company: '',
        address: '',
        fax: '',
        type: '请选择服务类型'
    })

    const form_two = reactive({
        username: '',
        phone: '',
        email: '',
        content: '',
        region: '',
        industry: '请选择您的行业',
        company: '',
        address: '',
        fax: '',
        type: '请选择服务类型'
    })

    const message = ref('')

    const alert = ref(false)

    const success = ref(false)

    const submit = () => {
        if(form.username == ''){
            alert.value = true
            message.value = '请输入联系人'
        }else if(form.phone == ''){
            alert.value = true
            message.value = '请输入联系电话'
        }else if(form.email == ''){
            alert.value = true
            message.value = '请输入邮箱'
        }else if(form.content == ''){
            alert.value = true
            message.value = '请输入内容'
        }else if(form.region == ''){
            alert.value = true
            message.value = '请输入所属地区'
        }else if(form.industry == '请选择您的行业'){
            alert.value = true
            message.value = '请选择所属行业'
        }else if(form.company == ''){
            alert.value = true
            message.value = '请输入公司名称'
        }else if(form.type == '请选择服务类型'){
            alert.value = true
            message.value = '请输入类型'
        }else{
            setPost()
        }
    }

    const setPost = async () => {
        await post('/contact/add_message', form).then((res) => {
            // console.log('添加留言', res)
            if(res.code == 1){
                alert.value = false
                success.value = true
                form.username =  '',
                form.phone =  '',
                form.email=  '',
                form.content =  '',
                form.region =  '',
                form.industry =  '请选择您的行业',
                form.company =  '',
                form.address =  '',
                form.fax =  '',
                form.type =  '请选择服务类型'
            }
        })
    }

    return { form, submit, message, alert, success, industryList, typeList }
}

export default {
    name: 'ContactMessage',
    components: { Header, Footer, FloatingFrame, ContactTop },
    setup() {

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { form, submit, message, alert, success, industryList, typeList } = formEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        return { isShow, header, form, submit, message, alert, success, industryList, typeList }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.content_box{
    margin-top: 50px;
}
.content{
    @media screen and (max-width: 1200px){
        padding: 0 30px;
    }
    .title{
        color: rgba(16, 16, 16, 100);
        font-size: 20px;
        font-weight: $all-font-weight;
        text-align: center;
    }
    .line{
        width: 100%;
        height: 1px;
        background: #bbb;
        margin-top: 50px;
    }
    .second{
        margin-top: 20px;
        .name{
            width: 16%;
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            @media screen and (max-width: 900px){
                width: 100px;
            }
            span{
                color: #00447d;
            }
        }
        textarea{
            width: 100%;
            height: 150px;
            outline: none;
            border: 1px solid #BBBBBB;
            color: #888888;
            padding: 10px 15px; 
            margin-top: 18px;
            &::placeholder{
                color: #888888;
            }
        }
        input{
            width: 100%;
            border: 1px solid #BBBBBB;
            padding: 10px 15px; 
            height: 50px;
            outline: none;
            color: #888888;
            margin-top: 18px;
            &::placeholder{
                color: #888888;
            }
        }
    }
    .third{
        display: flex;
        margin-top: 20px;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 900px){
            display: block;
        }
        .list{
            width: 46%;
            margin-right: 42px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            &:nth-child(2){
                margin-right: 0%;
            }
            @media screen and (max-width: 900px){
                width: 100%;
                margin-right: 0%;
                margin-bottom: 30px;
            }
            .name{
                width: 16%;
                color: rgba(16, 16, 16, 100);
                font-size: 14px;
                @media screen and (max-width: 900px){
                    width: 100px;
                }
                span{
                    color: #00447d;
                }
            }
            input{
                width: 100%;
                border: 1px solid #BBBBBB;
                padding: 10px 20px;
                height: 50px;
                outline: none;
                color: #888888;
                margin-top: 18px;
                &::placeholder{
                    color: #888888;
                }
            }
            select{
                width: 100%;
                border: 1px solid #BBBBBB;
                padding: 10px 20px;
                height: 50px;
                outline: none;
                color: #888888;
                background: #fff;
                margin-top: 18px;
                &::placeholder{
                    color: #888888;
                }
            }
        }
    }
    .btn{
        color: #fff;
        background: #2F509E;
        font-size: 16px;
        border: none;
        padding: 15px 30px;
        width: 150px;
        margin: 0 auto;
        display: block;
        margin-top: 50px;
        cursor: pointer;
        transition: .3s all;
        @media screen and (max-width: 900px){
            font-size: .14rem;
        }
    }
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>